import React from "react";

export default function UserReviews() {
    return (
        <section id='user_reviews' className="center">
                <table id='user_review_table' className="center" >
                    <tbody>
                    <tr>
                        <td>No Reviews Left</td>
                    </tr>
                    </tbody>
                </table>
            </section>
    )
}