import { useContext, useEffect, useState } from 'react'
import { LoadingContext, NotificationContext, server } from '../App'
import { createNotification } from '../utils/functions'
import '../styles/change-log.css'
import ChangeLog from '../components/ChangeLog'

export default function ChangeLogs() {
    const { loading, setLoading } = useContext(LoadingContext)
    const { setNotification } = useContext(NotificationContext)
    const [logs, setLogs] = useState(null)

    useEffect(() => {
        if (logs == null) {
            if (!loading) setLoading(true)
            server.get('/changes')
                .then(res => {
                    setLogs(res.data)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err);
                    var notification = createNotification('Server Error. Try again later')
                    setNotification(notification)
                    setLoading(false)
                })
        }
    }, [logs]);



    return (
        <>
            <h2 className="highlight_title">Change Log</h2>
            <>
                {logs != null &&
                    logs.map((log, key) => {
                        return <ChangeLog log={log} key={key} />
                    })
                }

                {logs != null && logs.length === 0 &&
                    <h2>No Logs found</h2>
                }
            </>

            {/* <div className="change_log">
                <div className="change_log_header center">
                    <p className="change_log_version blue_highlight">v0.1.0:</p>
                    <p className="change_log_title blue_highlight">Layouts and Design for Computer & Mobile</p>
                </div>

                <div className='change_log_body'>
                    <p className="change_log_description">
                        Hello World!
                        This is the first version of this web app ready for users on desktop and mobile. Due to redeployment, any user
                        data from the testing phase of deployment was deleted. This was done also as a security measure.
                    </p>

                    <h4>Changes:</h4>
                    <ul className="change_log_text">
                        <li>
                            Finalized design & layout of the Stat Check page, but more elements and details will be added to the page the future.
                        </li>
                        <li>
                            The Stat Check system has been implemented to receive forms from verified users, save their forms in the database,
                            and calculate winners for the new day when nessesary.
                        </li>
                        <li>
                            The layout of user registration, login, logout, and verification has been fully designed. Password reseting is the only
                            remaining aspect left to design.
                        </li>
                        <li>
                            Users can now make their own accounts using a unique username and email, and a non-empty 8 character log password.
                        </li>
                        <li>
                            Users are allowed to send 5 emails per today (Will probably be increased in the future), currently only able to request
                            verification emails.
                        </li>
                        <li>
                            Users after receiving a verification code will have 10 tries to verify their account with their code. Otherwise they
                            will need to request a new code
                        </li>
                        <li>
                            A drop down menu was made for mobile or small screen devices in order to navigate the website elegantly
                        </li>
                    </ul>
                </div>
            </div >


            <br></br>
            <br></br>
            <br></br>
            <br></br> */}


        </>
    )
}