import React from "react";

export default function FoodReview() {
    return (
        <>
            <h2 className="highlight_title">Food Review!</h2>

            <p>IN THE WORKS...</p>

            <h3 className="peach_highlight">DEADLINE: August 10th</h3>

        </>
    );
}