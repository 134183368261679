import React from "react";

export default function ServerDown() {
    return (
        <>
            <br></br>
            <br></br>
            <br></br>
            <h2 className="highlight_title">Server Down for Maintenance</h2>

            <p style={{fontSize: '20px'}}>
                We are working on fixing this as soon as possible. Thank
                you for your understanding.
            </p>

        </>
    )
}