import React from "react";

export default function Home() {
    return (
        <div id='home'>
            <h2 className="highlight_title">Home</h2>

            <p>IN THE WORKS...</p>

            <p> Description of website </p>

            <div>
                Two buttons for the two systems
            </div>

            <section>
                Announcements
            </section>

            <section>
                Home Calendar
            </section>


            <section>
                Feedback
            </section>



            <div className="footer">
                Footer at the bottom with contact information
            </div>
        </div>
    )
}