
export default function ChangeLog({ log }) {
    return (
        <div className="change_log">
            <div className="change_log_header center">
                <p className="change_log_version blue_highlight">{log.version}</p>
                <p className="change_log_version blue_highlight">-</p>
                <p className="change_log_title blue_highlight">{log.title}</p>
            </div>

            <div className='change_log_body'>
                <p className="change_log_description">
                    {log.description}
                </p>

                <h4>Changes:</h4>
                <ul className="change_log_text">
                    {
                        (log.text).map((line, key) => {
                            return <li key={key}>
                                {line}
                            </li>
                        })
                    }
                </ul>
            </div>
        </div>
    )
}